<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1>View Service Report</h1>
      </b-col>
      <b-col class="text-right">
        <b-button
          v-if="+$route.query.id"
          :variant="openSection ? 'primary' : 'outline-primary'"
          style="margin-bottom: 7px"
          class="btn-icon rounded-circle mr-1"
          @click="openSection = !openSection"
        >
          <feather-icon icon="MessageCircleIcon" size="16" />
        </b-button>
        <b-button
          v-b-modal.modal-manage
          v-if="+$route.query.id"
          variant="danger"
          style="margin-bottom: 7px"
          class="btn-icon rounded-circle"
        >
          <feather-icon icon="Trash2Icon" size="16" />
        </b-button>

        <Modal modalId="modal-manage" @onChange="(v) => onDelete()" />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <b-tabs v-model="activeTab" fill>
            <b-tab title="Information" active>
              <b-row>
                <b-col class="text-right">
                  <b-button
                    type="button"
                    class="mr-1"
                    variant="outline-danger"
                    @click="returnToPage"
                  >
                    Back
                  </b-button>
                  <b-button
                    :disabled="isLoading"
                    type="button"
                    class="mr-1"
                    variant="outline-primary"
                    @click="onDuplicate"
                  >
                    Duplicate
                  </b-button>
                  <b-button
                    v-if="formData.pdf"
                    type="button"
                    class="mr-1"
                    variant="info"
                    @click="generatePDF()"
                  >
                    Preview
                  </b-button>
                  <b-button
                    v-if="!formData.verified"
                    @click="verifyReport()"
                    type="button"
                    class="mr-1"
                    variant="secondary"
                  >
                    Verify
                  </b-button>
                  <b-button
                    v-if="toFollowUp"
                    @click="followUp()"
                    type="button"
                    class="mr-1"
                    variant="primary"
                  >
                    Create Follow-up Job
                  </b-button>
                  <b-button
                    v-if="!formData.verified"
                    @click="onEdit"
                    type="button"
                    variant="success"
                  >
                    Edit
                  </b-button>

                  <Modal
                    modalId="tab-information"
                    @onChange="(v) => onSubmit()"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" :md="openSection ? '9' : '12'">
                  <Alert :error="errors" :success="success" />

                  <b-row>
                    <b-col>
                      <small v-if="+$route.query.id">
                        last edited: {{ formData.preview_updated_at }}
                      </small>
                      <h2>
                        Service Report Information -
                        <strong>
                          {{ formData.preview_job_status }}
                          ({{ formData.job_name }})
                        </strong>
                      </h2>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="3">
                      <b-form-group label="Ticket No." label-for="v-ticket-no">
                        <b-form-input
                          disabled
                          v-model="formData.ticket_no"
                          id="v-ticket-no"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="5">
                      <b-form-group label="Company" label-for="v-company">
                        <b-form-input
                          v-model="formData.company_name"
                          id="v-company"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                      <b-form-group
                        label="Contract No."
                        label-for="v-ticket-no"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.contract_refnum"
                          id="v-ticket-no"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                      <b-form-group label="Po No." label-for="v-company">
                        <b-form-input
                          v-model="formData.contract_refnum"
                          id="v-company"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="3">
                      <b-form-group label="Job Name" label-for="v-ticket-no">
                        <b-form-input
                          v-model="formData.job_name"
                          disabled
                          id="v-ticket-no"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Job Type" label-for="v-company">
                        <b-form-input
                          v-model="formData.job_type"
                          id="v-company"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Job Status" label-for="v-company">
                        <b-form-input
                          v-model="formData.preview_job_status"
                          id="v-company"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Recipients" label-for="v-company">
                        <b-form-input
                          v-model="formData.email"
                          id="v-company"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="4">
                      <b-form-group
                        label="Customer Name"
                        label-for="v-customer_name"
                      >
                        <b-form-input
                          v-model="formData.customer_name"
                          id="v-customer_name"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                      <b-form-group label="Customer Email" label-for="v-email">
                        <b-form-input
                          v-model="formData.customer_email"
                          id="v-email"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                      <b-form-group
                        label="Customer Contact"
                        label-for="v-customer_contact"
                      >
                        <b-form-input
                          v-model="formData.customer_contact"
                          id="v-customer_contact"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="3">
                      <b-form-group
                        label="Report Date"
                        label-for="v-report_date-date"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.preview_report_date"
                          id="v-report_date-date"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group
                        label="Chargeable Amount"
                        label-for="v-chargeable_amount"
                      >
                        <b-form-input
                          disabled
                          class="text-right"
                          v-model="formData.chargeable_amount"
                          id="v-chargeable_amount"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group
                        label="New Chargeable Amount"
                        label-for="v-post_amount"
                      >
                        <b-form-input
                          disabled
                          class="text-right"
                          v-model.number="formData.post_amount"
                          id="v-post_amount"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group
                        label="New Chargeable Amount Remarks"
                        label-for="v-post_amount_remarks"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.post_amount_remarks"
                          id="v-post_amount_remarks"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="3">
                      <b-form-group
                        label="Chargeable Type"
                        label-for="v-chargeable_type"
                      >
                        <v-select
                          disabled
                          id="v-chargeable_type"
                          v-model="formData.chargeable_type"
                          :options="chargeableTypes"
                          :reduce="(chargeableTypes) => chargeableTypes.value"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group
                        label="Chargeable Hours"
                        label-for="v-chargeable_hours"
                      >
                        <b-form-input
                          disabled
                          id="v-chargeable_hours"
                          v-model.number="formData.chargeable_hours"
                          type="number"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Job Started" label-for="v-job_eta">
                        <b-form-input
                          disabled
                          v-model="formData.preview_job_eta"
                          id="v-job_eta"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Job Ended" label-for="v-job_eta">
                        <b-form-input
                          disabled
                          v-model="formData.preview_job_etd"
                          id="v-job_eta"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="Report Start"
                        label-for="v-report_start"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.preview_report_start"
                          id="v-job_eta"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="Report End" label-for="v-report_end">
                        <b-form-input
                          disabled
                          v-model="formData.preview_report_end"
                          id="v-job_eta"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Problem Description"
                        label-for="v-problem_description"
                      >
                        <b-form-textarea
                          disabled
                          id="v-problem_description"
                          v-model="formData.problem_description"
                          rows="4"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Services Performed"
                        label-for="v-services_performed"
                      >
                        <quill-editor
                          disabled
                          ref="quillEditorRefServicesPerformed"
                          v-model="formData.services_performed"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Job Description"
                        label-for="v-job_description"
                      >
                        <quill-editor
                          disabled
                          ref="quillEditorRef"
                          v-model="formData.job_instructions"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Assignment Information</h2>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Primary Technician"
                        label-for="v-sales-person"
                      >
                        <v-select
                          disabled
                          v-model.number="formData.job_assigned_to_1"
                          :options="employeeItems"
                          :reduce="(employeeItems) => +employeeItems.value"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Secondary Technician"
                        label-for="v-sales-person"
                      >
                        <v-select
                          disabled
                          v-model.number="formData.job_assigned_to_2"
                          :options="employeeItems"
                          :reduce="(employeeItems) => +employeeItems.value"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="6">
                      <h2>Job Site</h2>
                    </b-col>

                    <b-col cols="6" class="text-right">
                      <feather-icon
                        @click="isHidingForm = !isHidingForm"
                        class="pin isDisplaying"
                        :class="{
                          arrow_down: isHidingForm,
                          arrow_up: !isHidingForm,
                        }"
                        icon="ChevronUpIcon"
                        size="36"
                      />
                    </b-col>
                  </b-row>

                  <span v-if="!isHidingForm">
                    <b-row>
                      <b-col>
                        <b-form-group>
                          <GmapMap
                            :center="center"
                            :zoom="13"
                            map-type-id="terrain"
                            style="width: 100%; height: 400px"
                          >
                            <GmapMarker
                              :title="`${formData.addr1} ${formData.addr2}`"
                              :position="marker"
                            />
                          </GmapMap>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group
                          label="Site/Branch/Outlet Name"
                          label-for="v-site_id"
                        >
                          <b-form-input
                            disabled
                            v-model="formData.site_id"
                            id="v-site_id"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="2">
                        <b-form-group
                          label="Service Type"
                          label-for="v-service_type"
                        >
                          <v-select
                            disabled
                            id="v-service_type"
                            v-model="formData.contract_type"
                            :options="[
                              'project',
                              'installation',
                              'maintenance',
                              'chargeable',
                            ]"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="2">
                        <b-form-group
                          label="Service Location"
                          label-for="v-service_location"
                        >
                          <b-form-input
                            disabled
                            id="v-service_location"
                            v-model="serviceLocation"
                            class=""
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="6">
                        <b-form-group
                          label="Building/Block/House No. and Street Name"
                          label-for="v-addr1"
                        >
                          <b-form-input
                            disabled
                            v-model="formData.addr1"
                            id="v-addr1"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <b-form-group
                          label="Unit No. and Building Name"
                          label-for="v-addr2"
                        >
                          <b-form-input
                            disabled
                            v-model="formData.addr2"
                            id="v-addr2"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="6">
                        <b-form-group label="Country" label-for="v-country">
                          <b-form-input
                            disabled
                            v-model="formData.country"
                            id="v-country"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <b-form-group label="Postal Code" label-for="v-postal">
                          <b-form-input
                            disabled
                            v-model="formData.postal"
                            id="v-postal"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <ModalMap
                      :showMap="isShowingMap"
                      @onChangePlace="(v) => getPlace(v)"
                    />
                  </span>

                  <b-row>
                    <b-col cols="6">
                      <h2>Contact Information</h2>
                    </b-col>

                    <b-col cols="6" class="text-right">
                      <feather-icon
                        @click="isHidingFormContact = !isHidingFormContact"
                        class="pin isDisplaying"
                        :class="{
                          arrow_down: isHidingFormContact,
                          arrow_up: !isHidingFormContact,
                        }"
                        icon="ChevronUpIcon"
                        size="36"
                      />
                    </b-col>
                  </b-row>

                  <span v-if="!isHidingFormContact">
                    <b-row>
                      <b-col cols="12" md="6">
                        <b-form-group label="Contact Person" label-for="v-poc">
                          <b-form-input
                            disabled
                            v-model="formData.poc"
                            id="v-poc"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <b-form-group label="Mobile" label-for="v-mobile">
                          <b-form-input
                            disabled
                            v-model="formData.mobile"
                            id="v-mobile"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </span>

                  <b-row>
                    <b-col>
                      <h2>Proposed Spares Needed</h2>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <JobOrderSpares
                          isDisabled
                          :data-source="dataSourceSpares"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Products for servicing</h2>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <JobOrderDetails
                          isDisabled
                          :data-source="dataSourceDetails"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Images</h2>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <JobOrderImages
                          isDisabled
                          :data-source="dataSourceImages"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Customer checklist(s)</h2>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <JobOrderCustomerChecklists
                          :data-source="dataSourceCustomerChecklists"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Technician checklist(s)</h2>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <JobOrderTechnicianChecklists
                          :data-source="dataSourceTechnicianChecklists"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="6">
                      <h2>Other Information</h2>
                    </b-col>

                    <b-col cols="6" class="text-right">
                      <feather-icon
                        @click="isHidingFormRemarks = !isHidingFormRemarks"
                        class="pin isDisplaying"
                        :class="{
                          arrow_down: isHidingFormRemarks,
                          arrow_up: !isHidingFormRemarks,
                        }"
                        icon="ChevronUpIcon"
                        size="36"
                      />
                    </b-col>
                  </b-row>

                  <span v-if="!isHidingFormRemarks"
                    ><b-row>
                      <b-col cols="12">
                        <b-form-group label="GST" label-for="v-gst">
                          <b-form-input
                            disabled
                            v-model="formData.gst"
                            id="v-gst"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group
                          label="Instructions/Remarks"
                          label-for="v-remarks"
                        >
                          <b-form-textarea
                            disabled
                            v-model="formData.remarks"
                            id="v-remarks"
                            rows="4"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" md="4">
                        <b-form-group
                          label="Payment Mode"
                          label-for="v-payment_mode"
                        >
                          <b-form-input
                            disabled
                            v-model="formData.payment_mode"
                            id="v-payment_mode"
                            rows="4"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="4">
                        <b-form-group
                          label="Cheque No."
                          label-for="v-cheque_no"
                        >
                          <b-form-input
                            disabled
                            v-model="formData.cheque_no"
                            id="v-cheque_no"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="4">
                        <b-form-group
                          label="Follow-up Type"
                          label-for="v-job_followup_type"
                        >
                          <b-form-input
                            disabled
                            v-model="formData.job_followup_type"
                            id="v-job_followup_type"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12">
                        <b-form-group
                          label="Follow-up Description"
                          label-for="v-job_followup_description"
                        >
                          <b-form-textarea
                            disabled
                            v-model="formData.job_followup_description"
                            id="v-job_followup_description"
                            rows="4"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group
                          label="Customer Rating"
                          label-for="v-customer_rating"
                        >
                          <b-form-rating
                            no-border
                            inline
                            variant="warning"
                            v-model="formData.customer_rating"
                            disabled
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="6">
                        <b-form-group>
                          <b-card>
                            <b-row>
                              <b-col>
                                <b-form-group>
                                  <b-img
                                    style="width: 100%; height: 450px"
                                    :src="onGetCustomerSignature"
                                  />
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <b-form-group label="Signed By">
                                  {{ formData.customer_name }}
                                </b-form-group>
                              </b-col>
                              <b-col class="text-right">
                                <b-form-group label="Date Signed">
                                  {{ unixToDate }}
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-card>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <b-form-group>
                          <b-card>
                            <b-row>
                              <b-col>
                                <b-form-group>
                                  <b-img
                                    style="width: 100%; height: 450px"
                                    :src="onGetCompanyStamp"
                                  />
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <b-form-group label="Company Stamp">
                                  <br />
                                </b-form-group>
                              </b-col>
                              <b-col class="text-right">
                                <b-form-group label="Date Signed">
                                  {{ unixToDate }}
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-card>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </span>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <h2>PDF Versions</h2>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <JobOrderPDFVersions
                      isDisabled
                      :data-source="dataSourcePDFs"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <span v-if="$route.query.id">
              <b-tab @click="loadTimeline()" title="Timeline">
                <JobOrderTimeline
                  @on-submit-timeline="(timeline) => onSubmitTimeline(timeline)"
                  :data-source="dataSourceTimeline"
                />
              </b-tab>
              <b-tab title="Notes">
                <Notes ref="notesRef" module="JO" :header-id="getFormDataID" />
              </b-tab>
              <b-tab title="Documents">
                <Documents
                  ref="documentsRef"
                  module="JO"
                  :header-id="getFormDataID"
                />
              </b-tab>
              <b-tab title="Logs">
                <JobOrderLogs />
              </b-tab>
              <b-tab title="Comments">
                <JobOrderComments />
              </b-tab>
            </span>
          </b-tabs>
        </b-card>
      </b-col>

      <b-col cols="12" :md="openSection ? '3' : '12'" v-if="openSection">
        <b-row>
          <b-col>
            <b-card>
              <b-tabs fill>
                <b-tab active>
                  <template #title>
                    <small>Comments</small>
                  </template>
                </b-tab>
                <b-tab>
                  <template #title>
                    <small>Logs</small>
                  </template>
                </b-tab>
                <b-tab>
                  <template #title>
                    <small>Approval</small>
                  </template>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import jobOrderApi from '@api/joborder_headers';
import employeesApi from '@api/employees';
import usersApi from '@api/users';
import timelineHeadersApi from '@api/timeline_headers';
import timelinesApi from '@api/timelines';
import { queryParameters } from '@/schema';
import JobOrderSpares from './components/JobOrderSpares';
import JobOrderDetails from './components/JobOrderDetails';
import JobOrderImages from './components/JobOrderImages';
import JobOrderCustomerChecklists from './components/JobOrderCustomerChecklists';
import JobOrderTechnicianChecklists from './components/JobOrderTechnicianChecklists';
import JobOrderPDFVersions from './components/JobOrderPDFVersions';
import JobOrderTimeline from './components/JobOrderTimeline';
import JobOrderLogs from './components/JobOrderLogs';
import JobOrderComments from './components/JobOrderComments';

export default {
  name: 'ServiceReportView',
  components: {
    JobOrderSpares,
    JobOrderDetails,
    JobOrderImages,
    JobOrderTimeline,
    JobOrderCustomerChecklists,
    JobOrderTechnicianChecklists,
    JobOrderPDFVersions,
    JobOrderLogs,
    JobOrderComments,
  },
  data: () => ({
    center: { lat: 1.29027, lng: 103.851959 },
    marker: { lat: 1.29027, lng: 103.851959 },

    success: false,
    openSection: false,
    errors: {},
    activeTab: 0,
    isShowingMap: false,
    isHidingForm: false,
    isHidingFormContact: false,
    isHidingFormRemarks: false,
    isLoading: false,
    chargeableTypes: [
      { label: 'Hours', value: 'H' },
      { label: 'Token', value: 'T' },
    ],
    jobWorkModes: [
      { label: 'Onsite', value: 'O' },
      { label: 'Remote', value: 'R' },
    ],
    formData: {
      id: null,
    },
    employeeItems: [],
    dataSourceSpares: [],
    dataSourceDetails: [],
    dataSourceImages: [],
    dataSourceCustomerChecklists: [],
    dataSourceTechnicianChecklists: [],
    dataSourcePDFs: [],
    dataSourceTimeline: [],
    timelineHeaderId: 0,

    googleMapUrl: `https://maps.googleapis.com/maps/api/geocode/json?new_forward_geocoder=true&key=${process.env.VUE_APP_ROOT_GMAP_KEY}`,
  }),
  computed: {
    unixToDate: {
      get() {
        const { customer_signature_date } = this.formData;

        if (customer_signature_date) {
          return this.displayDateTimeFromUnix(customer_signature_date);
        }

        return '';
      },
    },
    onGetCompanyStamp: {
      get() {
        const url = process.env.VUE_APP_ROOT_STATIC_URL;
        if (this.formData.company_stamp) {
          return `${url}/joborder/${this.$route.query.id}/${this.formData.company_stamp}`;
        }

        return 'https://media.istockphoto.com/id/1472933890/vector/no-image-vector-symbol-missing-available-icon-no-gallery-for-this-moment-placeholder.jpg?s=612x612&w=0&k=20&c=Rdn-lecwAj8ciQEccm0Ep2RX50FCuUJOaEM8qQjiLL0=';
      },
      set(v) {
        //
      },
    },
    onGetCustomerSignature: {
      get() {
        const url = process.env.VUE_APP_ROOT_STATIC_URL;
        if (this.formData.customer_signature) {
          return this.formData.customer_signature;
        }

        return 'https://media.istockphoto.com/id/1472933890/vector/no-image-vector-symbol-missing-available-icon-no-gallery-for-this-moment-placeholder.jpg?s=612x612&w=0&k=20&c=Rdn-lecwAj8ciQEccm0Ep2RX50FCuUJOaEM8qQjiLL0=';
      },
      set(v) {
        //
      },
    },
    toFollowUp: {
      get() {
        const { job_status, job_followup_type } = this.formData;

        return (
          job_status === '10' &&
          job_followup_type === 'Job Completed with Follow Up'
        );
      },
    },
    serviceLocation: {
      get() {
        const { service_location } = this.formData;

        return service_location === 'O' ? 'On-Site' : 'Remote';
      },
    },
    getFormDataID: {
      get() {
        return parseInt(this.formData.id);
      },
    },
  },
  watch: {
    section(v) {
      this.openSection = v;
    },
  },
  mounted() {
    this.loadUsers();
    if (+this.$route.query.id) {
      this.loadJobRequest();
    }
  },
  methods: {
    loadEmployees() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      employeesApi
        .list(params)
        .then(({ data }) => {
          this.employeeItems = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadUsers() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      usersApi
        .list(params)
        .then(({ data }) => {
          const items = [];

          for (let i = 0; i < data.length; i++) {
            const item = data[i];
            const { id, first_name, last_name, username } = item;

            let fullName = first_name || username;
            if (first_name && last_name) {
              fullName += ` ${last_name}`;
            }

            items.push({ label: fullName, value: parseInt(id), item });
          }

          this.employeeItems = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadJobRequest() {
      jobOrderApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          if (data) {
            const {
              company_id,
              longitude: lng,
              latitude: lat,
              report_date,
              job_eta,
              job_etd,
              job_status,
              job_id,
              report_start,
              report_end,
              updated_at,
              post_amount,
              chargeable_amount,
            } = data;
            this.center = { lat, lng };
            this.marker = { lat, lng };

            const displayToLocale = (date) => {
              if (date) {
                return this.displayDateTimeFromUnix(date);
              }
            };

            data.preview_job_status = this.getJobStatus(job_status);

            data.preview_updated_at = displayToLocale(updated_at);
            data.preview_report_date = displayToLocale(report_date);
            data.preview_job_eta = displayToLocale(job_eta);
            data.preview_job_etd = displayToLocale(job_etd);
            data.preview_report_start = displayToLocale(report_start);
            data.preview_report_end = displayToLocale(report_end);

            if (data.company_id && data.joborder_company.data) {
              data.company_name = data.joborder_company.data.company_name;
            }

            if (job_id && data.joborder_job.data) {
              data.job_name = data.joborder_job.data.name;
            }

            if (data.site_id && data.joborder_site.data) {
              const site = data.joborder_site.data;
              data.addr1 = site.addr1;
              data.addr2 = site.addr2;
              data.country = site.country;
              data.postal = site.postal;
            }

            data.job_name = data.joborder_job.data.name;

            const getDetails = (details) => {
              if (details && details.data) {
                return details.data;
              }

              return [];
            };

            data.post_amount = this.moneyFormat(post_amount).replace('$', '');
            data.chargeable_amount = this.moneyFormat(
              chargeable_amount
            ).replace('$', '');

            this.dataSourceSpares = getDetails(data.joborder_spares);
            this.dataSourceDetails = getDetails(data.joborder_details);
            this.dataSourceImages = getDetails(data.joborder_images);
            this.dataSourcePDFs = getDetails(data.joborder_pdf_versions);

            this.formData = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadTimeline() {
      timelineHeadersApi
        .get(this.$route.query.id, 'timeline')
        .then(({ data }) => {
          if (data.timeline) {
            const { data: timelines } = data.timeline;
            this.timelineHeaderId = parseInt(data.id);
            this.dataSourceTimeline = timelines;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmitTimeline(item) {
      item.header_id = this.timelineHeaderId;
      item.ticket_no = this.formData.ticket_no;
      item.refnum = this.formData.refnum;

      timelinesApi
        .add(item)
        .then((data) => {
          if (data) {
            this.loadTimeline();
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onEdit() {
      const url = `/pages/services-tools/service-report/manage?id=${this.$route.query.id}`;
      this.$router.push(url);
    },
    onUpdateJobStatus(v) {
      const formData = {
        id: parseInt(this.$route.query.id),
        jobstatus: v,
      };

      jobOrderApi
        .updatejobstatus(formData)
        .then(({ data }) => {
          if (data) {
            this.formData.preview_job_status = this.getJobStatus(v);
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    generatePDF() {
      const url = process.env.VUE_APP_ROOT_STATIC_URL;
      if (this.formData.pdf) {
        window.open(`${url}/joborder/${this.formData.pdf}`, '_blank');
      }
    },
    returnToPage() {
      this.$router.push(`/pages/services-tools/service-report`);
    },
    getPlace(place) {
      //
    },
    verifyReport() {
      jobOrderApi
        .verify(this.$route.query.id)
        .then((data) => {
          this.loadJobRequest();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    followUp() {
      jobOrderApi
        .followUp(this.$route.query.id)
        .then(({ data }) => {
          const { id } = data;
          const url = `/pages/services-tools/service-report/view?id=${id}`;
          this.$router.push(url);
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onDuplicate(event) {
      this.isLoading = true;
      const { key: from_id } = event.row;
      const formData = { from_id };

      jobOrderApi
        .duplicate(formData)
        .then(({ data }) => {
          if (data) {
            this.isLoading = false;
            const url = `/pages/services-tools/service-report/view?id=${data.id}`;
            window.location.replace(url);
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.pin:hover {
  cursor: pointer;
}

.isDisplaying.arrow_down {
  transform: rotate(180deg);
  transition: transform 0.25s linear;
}

.isDisplaying.arrow_up {
  transform: rotate(360deg);
  transition: transform 0.25s linear;
}
</style>
